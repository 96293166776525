<template>
  <div>
    <el-form
      :rules="rules"
      :model="addDistrictForm"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="title" label="Полное наименование участка">
            <el-input v-model="addDistrictForm.title"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="parentInfo"
            label="Объект / Филиал / Название подразделения"
          >
            <el-select
              style="width: 100%"
              clearable
              value-key="id"
              v-model="addDistrictForm.parentInfo"
              filterable
              placeholder="Выберите"
            >
              <el-option
                v-for="item in organizationHierarchyByType"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-button @click="addDist" type="primary">Сохранить</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "districtsAdd",
  data() {
    return {
      addDistrictForm: {
        parentInfo: null,
        title: null,
        type: 3,
      },
      rules: {
        parentInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        title: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
    }),
  },

  methods: {
    addDist() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store.dispatch("createUnit", this.addDistrictForm).then(() => {
            this.notification("Успешно", "Участок добавлен", "success");
            this.$store.dispatch("getListUnits", {
              unitType: 3,
              page: 0,
              size: 20,
            });
            this.$store.dispatch("setShowModal", null);
          });
        }
      });
    },

    getOrganizationHierarchyByType() {
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 2,
      });
    },
  },

  mounted() {
    this.getOrganizationHierarchyByType();
  },
};
</script>

<style scoped></style>
