<template>
  <div>
    <districts-main></districts-main>
  </div>
</template>

<script>
import DistrictsMain from "@/components/ecologist/districts/districtsMain";
export default {
  name: "districtsMainView",
  components: { DistrictsMain },
};
</script>

<style scoped></style>
