<template>
  <div>
    <search-block :title="участка">
      <el-row :gutter="10">
        <el-col :span="20">
          <el-input @input="searchUnit" v-model="search"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button @click="showAddModal" type="primary"> Добавить</el-button>
        </el-col>
      </el-row>
    </search-block>
    <el-table stripe border :data="unitList.content">
      <el-table-column label="Наименование">
        <template slot-scope="row">
          {{ row.row.title }} ({{ row.row.path }})
        </template>
      </el-table-column>
      <el-table-column label="Ответственные">
        <template slot-scope="row">
          <ul>
            <li
              :key="key"
              v-for="(responsible, key) in row.row.responsiblePersonsInfo"
            >
              {{ responsible.name }}
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column width="150" label="">
        <template slot-scope="row">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="width: 100%"
                @click="showUpdateModal(row.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                @click="deleteDistrict(row)"
                size="mini"
                style="width: 100%; margin-left: 0; margin-top: 5px"
                type="danger"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="unitList.size"
      :current-page.sync="unitList.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="unitList.totalElements"
    >
    </el-pagination>

    <custom-modal
      title="Добавить участок"
      :visible="showModal === 'addDistricts'"
    >
      <districts-add></districts-add>
    </custom-modal>

    <custom-modal
      title="Изменить участок"
      :visible="showModal === 'updateDistricts'"
    >
      <districts-edit :selected-district="selectedDisctrict"></districts-edit>
    </custom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomModal from "@/components/customModal";
import DistrictsAdd from "@/components/ecologist/districts/districtsAdd";
import DistrictsEdit from "@/components/ecologist/districts/districtsEdit";
import SearchBlock from "@/components/searchBlock";

export default {
  name: "districtsTable",
  components: { SearchBlock, DistrictsEdit, DistrictsAdd, CustomModal },
  data() {
    return {
      search: "",
      UNIT_TYPE: 3,
      selectedDisctrict: {},
    };
  },

  computed: {
    ...mapGetters({
      unitList: "GETUNITSLIST",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    searchUnit(query) {
      this.$store.dispatch("searchUnits", {
        unitType: this.UNIT_TYPE,
        page: 0,
        size: 20,
        query: query,
      });
    },

    showUpdateModal(item) {
      this.selectedDisctrict = item;
      if (this.selectedDisctrict.dateFrom!=null)
      this.selectedDisctrict.dateFrom=new Date(this.selectedDisctrict.dateFrom);
      if (this.selectedDisctrict.dateTo!=null)
      this.selectedDisctrict.dateTo=new Date(this.selectedDisctrict.dateTo);
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 1,
      });
      this.$store.dispatch("setShowModal", "updateDistricts");
    },

    showAddModal() {
      this.$store.dispatch("setShowModal", "addDistricts");
    },

    handleSizeChange(val) {
      this.getListUnits({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getListUnits({ page: val - 1, size: this.unitList.size });
    },

    deleteDistrict(item) {
      this.confirm(
        "Удалить участок",
        "Вы действительно хотите удалить участок",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deleteOrganizationUnit", item.row.organizationId)
          .then(() => {
            this.notification("Успешно", "Участок удален", "success");
            this.getListUnits({
              page: this.unitList.number,
              size: this.unitList.size,
            });
          });
      });
    },

    getListUnits(data) {
      this.$store.dispatch("getListUnits", {
        unitType: this.UNIT_TYPE,
        page: data.page,
        size: data.size,
      });
    },
  },

  mounted() {
    this.getListUnits({ size: 20, page: 0 });
  },
};
</script>

<style scoped></style>
