<template>
  <div>
    <h2>Участки</h2>

    <districts-table></districts-table>
  </div>
</template>

<script>
import DistrictsTable from "@/components/ecologist/districts/districtsTable";
export default {
  name: "districtsMain",
  components: { DistrictsTable },
};
</script>

<style scoped></style>
